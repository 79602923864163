// Misc.
	$misc: (
		bg:					#fdfdfd url("../images/trees.jpg") bottom left,
		bg-width:			1500px
	);

// Duration.
	$duration: (
		bg:					60s,
		wrapper:			3s,
		overlay:			1.5s,
		header:				1s,
    	nav-icons:			0.5s,
    	content:         	2s
	);

// Size.
	$size: (
		nav-icon-wrapper:	5.35em,
		nav-icon:			1.75em
	);

// Font.
	$font: (
	);

// Palette.
	$palette: (
		bg:					#fff,
    	mg:         		#6D6F72,
		fg:					#1A1818,

		nav-icon: (
			hover-bg:		rgba(255,255,255,0.175),
			hover-fg:		#6D6F72,
			active-bg:		rgba(255,255,255,0.35),
			active-fg:		#fff
		)
	);
