@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/* Header */

	#header {
		nav {
			a {
				position: relative;
				border: solid 1px _palette(fg);
				border-radius: 100%;
				-ms-behavior: url('assets/js/ie/PIE.htc');

				&:before {
					border: 0;
				}
			}
		}
	}

/* Footer */

	#footer {
		background-image: url('images/ie/footer.png');
		background-position: bottom left;
		background-repeat: repeat-x;
	}
